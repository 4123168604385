

















































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import OrderService from "@/services/OrderService";
import Order, {OrderSelect, OrderType} from "@/models/Order";
import Options from "@/models/vue/Options";
import ClientService from "@/services/ClientService";
import Client from "@/models/Client";
import SessionModule from "@/store/SessionModule";
import {getModule} from "vuex-module-decorators";
import User from "@/models/User";
import UserService from "@/services/UserService";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import LangModule from "@/store/LangModule";
import OrderDetail from "@/models/OrderDetail";
import OrderStatusComponent from "@/components/OrderStatusComponent.vue"

@Component({components: { OrderStatusComponent }})
export default class OrdersView extends Vue {

	//TODO: Eliminar este componente.

	lang: any = getModule(LangModule).lang
	loading: boolean = false
	sendButtonDisabled = true
	page: number = 1
	pageCount: number = 0
	itemsPerPage: number = 10
	totalItems: number = 0
	search: string = ""
	orders: Order[] = []
	selected: Order[] = []

	headers = [
		{ align: "center", text: this.lang.date, value: "createdAt", 			width: "auto" },
		{ align: "center", text: this.lang.createdBy, value: "createdBy", width: "auto" },
		{ align: "center", text: this.lang.status, value: "status", 			width: "auto" },
		{ align: "center", text: this.lang.actions, value: "actions", 		width: "auto" },
		{ align: "center", text: "Xgest ID", value: "connectorId", 				width: "auto" },
	]

	headersWeb = [
		{ text: "Xgest ID", align: 'center', value: 'ref', width: 'auto' },
		{ text: this.lang.buyDateAtHeader, align: 'center', value: 'createdAt', width: 'auto' },
		{ text: this.lang.status, align: 'center', value: 'status', width: 'auto' },
		{ text: this.lang.createdBy, align: "center", value: "createdBy", width: "auto" },
		{ text: this.lang.total, align: 'center', value: 'totalAmount', width: 'auto' },
		{ text: this.lang.edit, align: 'center', value: 'edit', width: 'auto' },
	]

	options: Options = new Options()
	picker: string = ""
	dates: string[] = [this.picker]
	clients: Client[] = []
	client: Client = new Client()
	commercial: User = new User()
	commercials: User[] = []
	clientsPage: number = 0
	// @ts-ignore
	orderType: OrderType | null = null

	orderTypes: OrderSelect[] = [
		{name: this.lang.onHold, value: OrderType.WAITING},
		{name: this.lang.sent, value: OrderType.SEND},
		{name: this.lang.draft, value: OrderType.DRAFT},
	]

	//Filter models
	commercialId: number | null = null
	clientId: number | null = null

	orderTypeFinish: OrderType = OrderType.FINISH
	orderTypeSend: OrderType = OrderType.SEND
	orderTypeDraft: OrderType = OrderType.DRAFT
	sessionModule: SessionModule = getModule(SessionModule)
	isCommercial = this.sessionModule.session.authorities.map(e => e.title == "commercial")[0]
	all: boolean = false
	filterColumn: number = 4
	orderColumn: number = 8
	expand: boolean = true

	expandFilters() {
		this.expand = !this.expand
		if (!this.expand) {
			this.filterColumn = 2
			this.orderColumn = 10
		}
		if (this.expand) {
			this.filterColumn = 4
			this.orderColumn = 8
		}
	}

	async exportToCsv() {
		await OrderService.getMyOrdersCsv(
			 this, this.page - 1, this.itemsPerPage, this.commercialId,
			 this.search, this.orderType, this.clientId, this.dates[0], this.dates[1]
		)
	}

	getTotalPrice(orderDetails: OrderDetail[]) {
		let totalPrice: number = 0
		orderDetails.forEach(orderDetail => { totalPrice += (Number(orderDetail.product!.commercialRate) * Number(orderDetail.quantity)) })
		return totalPrice
	}

	isSelectable(item: Order){
		return item.orderType !== OrderType.WAITING
	}

	@Watch("selected")
	sendBtnHidden(){
		console.log(this.selected)
		this.sendButtonDisabled = this.selected.length == 0
	}

	async sendOrdersButtonClick(){
		for (let item of this.selected) {
			if(item.id != null) {
				await OrderService.sendOrder(this, item.id)
				await this.refresh()
			}
		}
	}

	clearCommercial() {
		this.commercial = new User()
		this.watchOptions()
	}

	isDisableEdit(item: Order): boolean {
		return (item.orderType === this.orderTypeFinish) || (item.orderType === this.orderTypeSend)
	}

	isDisableView(item: Order): boolean {
		return (item.orderType === this.orderTypeDraft)
	}

	async loadClients([intersectionEntry]: IntersectionObserverEntry[]) {
		if (intersectionEntry.isIntersecting) {
			const oldClients: Client[] = [...this.clients]
			this.clientsPage++
			await this.getClients()
			const newClients: Client[] = [...this.clients]
			this.clients = [...oldClients, ...newClients]
		}
	}

	async getClients() {
		// await ClientService.getClients(this, this.clients, this.clientsPage, 10, "")
		if (this.$route.query.clientId) {
			const id = this.$route.query.clientId
			const client = this.clients.find(e => e.id === Number(id))
			console.log(client!.email)
			this.client = client!
			await this.watchOptions()
		}
	}

	created() {
		if (this.$route.query.all === "1") {
			this.all = true
		}
		this.getClients()
		this.getCommercials()
	}

	async getCommercials() {
		await UserService.getMyCommercials(this, this.commercials, 0, 100, false, true)
		if (this.$route.query.commercial) {
			const id = this.$route.query.commercial
			const commercial = this.commercials.find(e => e.id === Number(id))
			console.log(commercial)
			this.commercial = commercial!
			await this.watchOptions()
		}
	}

	@Watch("options")
	async watchOptions() {
		console.log("WatchOptions")
		if (this.isCommercial || this.all) {

			await OrderService.getMyOrdersByUser(
				 this, this.orders, this.page - 1, this.itemsPerPage, this.sessionModule.session!.user!.id!,
				 this.search, this.orderType, this.client.id, this.dates[0], this.dates[1]
			)
		} else {

			await OrderService.getMyOrders(
				 this, this.orders, this.page - 1, this.itemsPerPage, this.commercialId,
				 this.search, this.orderType, this.clientId, this.dates[0], this.dates[1]
			)
		}
	}

	@Watch("all")
	watchAll() {
		this.watchOptions()
	}

	async refresh() {
		await this.watchOptions()
		this.selected = []
	}

	rowClick(order: Order) {
		this.$router.push('/order-details/' + order.id)
	}

	deleteOrder(id: number) {
		getModule(DialogModule).showDialog(new Dialog(
			 this.lang.warning,
			 this.lang.deleteOrderQuestion,
			 () => OrderService.deleteOrder(this, id)
		))
	}

}
